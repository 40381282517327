import { useState, useEffect } from 'react'
import axios from 'axios'
import Table from 'react-bootstrap/Table'
import { Button } from 'react-bootstrap';
import { useNavigate} from 'react-router-dom'
import Login from'../components/Login'
import { FaEdit, FaTrashAlt } from 'react-icons/fa';
import useUrl from '../components/useUrl';



export default function Szempontok(props) {
	const token = localStorage.getItem("token")
	const szempontsor = props.szempontsor.id
	const szempontsorNev = props.szempontsor.megnevezes
	const szempontokSzama = 10

	const [data, setData] = useState([]);
	const navigate = useNavigate();
	const baseUrl = useUrl()
	
	const adatokLekerese = async () => {
		try {
			const res = await axios.get(baseUrl + 'szempont/szempontsor/'+szempontsor, {
				headers: { 
					'Content-type': 'application/vnd.api+json',
					'Accept': 'application/vnd.api+json',
					'Authorization': `Bearer ${token}`,
				}
			});
			setData(res.data.data);
		} catch (error) {
			//console.log(error);
			alert("Hiba történt.\n\n" + error.message)
			return
		}
	};

	useEffect(() => {
		adatokLekerese()
	}, []);
	const navNew = () => { navigate('../szempontok/new',{state:{
		szempontsorID: szempontsor,
		szempontsorNev: szempontsorNev
	}})}
	const navShow = (id) => { navigate('../szempontok/show/'+id)}
	const navDelete = (id) => { navigate('../szempontok/delete/'+id)}

	const arr = data.map((data, index) => {
		return (
			<tr key={data.id}>
                <td onClick={()=>navShow(data.id)}>{data.terulet}</td>
                <td onClick={()=>navShow(data.id)}>{data.szempont}</td>
                <td onClick={()=>navShow(data.id)}>{data.ertek}</td>
                <td onClick={()=>navShow(data.id)}>{data.sulyszorzo}</td>
                <td onClick={()=>navShow(data.id)}>{data.sulyozott}</td>
				<td>
				{(data.szempontsor_lezarva=='1') ? (<></>) :
					(<><Button variant='success' className='me-3' onClick={()=> {
						navigate('../szempontok/edit/'+data.id,{state:{
							szempontsorID: data.szempontsor_id,
							szempontsorNev: data.szempontsor_megnevezes,
							terulet: data.terulet,
							szempont: data.szempont,
							magyarazat: data.magyarazat,
							kiegeszites: data.kiegeszites,
							kapcsolodo: data.kapcsolodo,
							adatforras: data.adatforras,
							ertek: data.ertek,
							sulyszorzo: data.sulyszorzo,
							sulyozott: data.sulyozott
						}}
						)}}>
					<FaEdit/>
					</Button>
					<Button variant='danger' onClick={()=>navDelete(index = data.id)}><FaTrashAlt/></Button></>)
				}
				</td>
			</tr>
		)
	})

	return (
		token ?
		<div>
			<h3>Szempontok</h3>
			
			{(arr.length < szempontokSzama) ?
				<>
					<Button variant='info' className='mb-3' onClick={navNew}>Új szempont rögzítése</Button>
					<p className='text-small'>(A szempontsorhoz legfeljebb 10 szempont rögzíthető.)</p>
				</>
				:<></>}
			<Table striped bordered hover responsive>
				<thead><tr>
					<th>Terület</th>
                    <th>Szempont</th>
                    <th>Érték</th>
                    <th>Súlyszorzó</th>
                    <th>Súlyozott</th>
					<th />
				</tr></thead>
				<tbody>{arr}</tbody>
			</Table>
		
			<p>Szempontok száma: {arr.length}</p>
		</div>
		

		: <Login />
	);
}