import {useState} from 'react';
import {Form, Row, Col, Button} from 'react-bootstrap';
import axios from 'axios';
import {useParams, useLocation, useNavigate} from 'react-router-dom'
import useUrl from '../../components/useUrl';


function Edit() {

  let { index } = useParams();
  const {state} = useLocation();
  const baseUrl = useUrl()

  const {nev, omAzonosito, iranyitoszam, varos, utca, telefon, email, logo, fenykep } = state || {};
  const [formNev, setFormNev] = useState(state.nev);
  const [formOMazonosito, setFormOMazonosito] = useState(state.omAzonosito);
  const [formIranyitoszam, setFormIranyitoszam] = useState(state.iranyitoszam);
  const [formVaros, setFormVaros] = useState(state.varos);
  const [formUtca, setFormUtca] = useState(state.utca);
  const [formTelefon, setFormTelefon] = useState(state.telefon);
  const [formEmail, setFormEmail] = useState(state.email);
  
  const token = localStorage.getItem("token")
  const navigate = useNavigate()

  const [validated, setValidated] = useState(false);
    const handleSubmit = (event) => {
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }
      setValidated(true);
      if (form.checkValidity() === true) {
        adatokKuldese();
        navigate('../iskolak')
      }
    };

    const adatokKuldese = () => {
      var config = {
        method: 'patch',
        url: baseUrl + 'iskola/'+index,
        headers: { 
          'Accept': 'application/vnd.api+json', 
          'Content-Type': 'application/vnd.api+json', 
          'Authorization': 'Bearer '+token
        },
        data: {
          'nev': formNev,
          'om_azonosito': formOMazonosito,
          'iranyitoszam': formIranyitoszam,
          'varos': formVaros,
          'utca': formUtca,
          'telefon': formTelefon,
          'email': formEmail
        }
      };
      axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
        return
      })
      .catch(function (error) {
        //console.log(error);
        alert("Hiba történt.\n\n" + error.message)      });
    };

  return (
    <>
    <h1>Iskola adatainak szerkesztése</h1>
    <Row pt={5}>
      <Col lg="6">
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Név: </Form.Label>
            <Form.Control type="text" placeholder="Írd be az iskola nevét!" value={formNev} required
              onChange={(e) => { setFormNev(e.target.value) }}/>
            <Form.Control.Feedback type="invalid">
              A kitöltés kötelező.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>OM azonosító: </Form.Label>
            <Form.Control type="text" placeholder="OM azonosító" value={formOMazonosito} 
              onChange={(e) => { setFormOMazonosito(e.target.value) }}/>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Irányítószám: </Form.Label>
            <Form.Control type="text" placeholder="Irányítószám" value={formIranyitoszam}
              onChange={(e) => { setFormIranyitoszam(e.target.value) }}/>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Város: </Form.Label>
            <Form.Control type="text" placeholder="Város" value={formVaros} required
              onChange={(e) => { setFormVaros(e.target.value) }}/>
            <Form.Control.Feedback type="invalid">
              A kitöltés kötelező.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Utca: </Form.Label>
            <Form.Control type="text" placeholder="Utca" value={formUtca} 
              onChange={(e) => { setFormUtca(e.target.value) }}/>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Telefon: </Form.Label>
            <Form.Control type="text" placeholder="Telefonszám" value={formTelefon}
              onChange={(e) => { setFormTelefon(e.target.value) }}/>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Email: </Form.Label>
            <Form.Control type="email" placeholder="E-mail cím" value={formEmail}
              onChange={(e) => { setFormEmail(e.target.value) }}/>
            <Form.Control.Feedback type="invalid">
              Nem megfelelő a beírt cím.
            </Form.Control.Feedback>
          </Form.Group>
          <Button type="submit" className='mb-3'>Adatok küldése</Button>
        </Form>
        <Button variant='secondary' onClick = {()=>navigate('../iskolak')}>Vissza az iskolák listájához</Button>
      </Col>
    </Row>
    </>
  )
}

export default Edit