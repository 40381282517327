import {useState} from 'react';
import {useNavigate, useLocation} from 'react-router-dom'
import {Form, Row, Col, Button} from 'react-bootstrap';
import axios from 'axios';
import useUrl from '../../components/useUrl';

function New() {
  const baseUrl =useUrl()
  const {state} = useLocation();
  const { szempontsorID, szempontsorNev} = state || {};
    const formSzempontsorID = state.szempontsorID;
    const formSzempontsorNev = state.szempontsorNev;
    const [formTerulet, setFormTerulet] = useState('');
    const [formSzempont, setFormSzempont] = useState('');
    const [formMagyarazat, setFormMagyarazat] = useState('');
    const [formKiegeszites, setFormKiegeszites] = useState('');
    const [formKapcsolodo, setFormKapcsolodo] = useState('');
    const [formAdatforras, setFormAdatforras] = useState('');
    const [formErtek, setFormErtek] = useState('');
    const [formSulyszorzo, setFormSulyszorzo] = useState('');
    const [formSulyozott, setFormSulyozott] = useState('');

    const token = localStorage.getItem("token")
    const navigate = useNavigate()

    const [validated, setValidated] = useState(false);
    const handleSubmit = (event) => {
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }
      setValidated(true);
      if (form.checkValidity() === true) {
        adatokKuldese();
        navigate('../szempontsorok/show/'+formSzempontsorID)
      }
    };

    const adatokKuldese = () => {
      var config = {
        method: 'post',
        url: baseUrl + 'szempont',
        headers: { 
          'Accept': 'application/vnd.api+json', 
          'Content-Type': 'application/vnd.api+json', 
          'Authorization': 'Bearer '+token
        },
        data: {
          'szempontsor_id': formSzempontsorID,
          'terulet': formTerulet,
          'szempont': formSzempont,
          'magyarazat': formMagyarazat,
          'kiegeszites': formKiegeszites,
          'kapcsolodo': formKapcsolodo,
          'adatforras': formAdatforras,
          'ertek': formErtek,
          'sulyszorzo': formSulyszorzo,
          'sulyozott': formErtek*formSulyszorzo
        }
      };
      axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
        return
      })
      .catch(function (error) {
        console.log(error);
        alert("Hiba történt.\n\n" + error.message)
      });
    };

  return (
    <>
    <h1>Új szempont beírása</h1>
    <Row pt={5}>
      <Col lg="9">
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Szempontsor: <b>{formSzempontsorID} - {formSzempontsorNev}</b></Form.Label>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Terület: </Form.Label>
            <Form.Control type="text" placeholder="Terület" value={formTerulet} required
              onChange={(e) => { setFormTerulet(e.target.value) }}/>
          </Form.Group>
          
          <Form.Group className="mb-3">
            <Form.Label>Szempont: </Form.Label>
            <Form.Control as="textarea" rows={3} placeholder="Szempont" value={formSzempont || ''} required
              onChange={(e) => { setFormSzempont(e.target.value) }}/>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Magyarázat: </Form.Label>
            <Form.Control as="textarea" rows={3} placeholder="Magyarázat" value={formMagyarazat  || ''} required
              onChange={(e) => { setFormMagyarazat(e.target.value) }}
              />
          </Form.Group>
          
          <Form.Group className="mb-3">
            <Form.Label>Kiegészítés: </Form.Label>
            <Form.Control as="textarea" rows={3} placeholder="Kiegészítés" value={formKiegeszites || ''} 
              onChange={(e) => { setFormKiegeszites(e.target.value) }}/>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Kapcsolódó: </Form.Label>
            <Form.Control as="textarea" rows={3} placeholder="Kapcsolódó tartalom, amit az iskola határoz meg." value={formKapcsolodo || ''} 
              onChange={(e) => { setFormKapcsolodo(e.target.value) }}/>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Adatok forrása: </Form.Label>
            <Form.Control as="textarea" rows={3} placeholder="Adatok forrása" value={formAdatforras || ''} required
              onChange={(e) => { setFormAdatforras(e.target.value) }}/>
          </Form.Group>
          
          <Form.Group className="mb-3">
            <Row>
              <Col sm={4}>
                <Form.Label>Érték: </Form.Label>
                <Form.Control type="number" placeholder="Érték" value={formErtek} required
                  onChange={(e) => { setFormErtek(e.target.value) }}/>
              </Col>
              <Col sm={4}>
                <Form.Label>Súlyszorzó: </Form.Label>
                <Form.Control type="number" placeholder="Súlyszorzó" value={formSulyszorzo} required
                  onChange={(e) => { setFormSulyszorzo(e.target.value) }}/>
              </Col>
              <Col sm={4}>
                <Form.Label>Súlyozott: </Form.Label>
                <Form.Control type="number" placeholder="Súlyozott érték" value={formErtek*formSulyszorzo} disabled
                  onChange={(e) => { setFormSulyozott(e.target.value) }}/>
              </Col>
            </Row>
          </Form.Group>

          <Button type="submit" className='mb-3'>Adatok küldése</Button>
        </Form>
      </Col>
    </Row>
    </>
  )
}

export default New