import {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom'
import {Form, Row, Col, Button} from 'react-bootstrap';
import axios from 'axios';
import useUrl from '../../components/useUrl';

function New() {

  const token = localStorage.getItem("token")
  const navigate = useNavigate()
  const baseUrl = useUrl()

    const [formVezeteknev, setFormVezeteknev] = useState('');
    const [formKeresztnev, setFormKeresztnev] = useState('');
    const [formOMazonosito, setFormOMazonosito] = useState('');
    const formIskolaID = localStorage.getItem("iskolaID")
    const formIskolaNev = localStorage.getItem("iskolaNev")
    const [formMunkakorID, setFormMunkakorID] = useState(0);
    const [formVegzettsegek, setFormVegzettsegek] = useState('');
    const [formTovabbkepzesek, setFormTovabbkepzesek] = useState('');
    const [formOneletrajz, setFormOneletrajz] = useState('');
    const [munkakorok, setMunkakorok] = useState([]);

    const munkakorokLekerese = async () => {
      try {
        const res = await axios.get(baseUrl + 'munkakor', {
          headers: { 
            'Content-type': 'application/vnd.api+json',
            'Accept': 'application/vnd.api+json',
            'Authorization': `Bearer ${token}`
          }
        });
        setMunkakorok(res.data.data);
      } catch (err) {
        alert("Hiba történt.\n\n" + err.message)
      }
    };

    const arrMunkakorok = munkakorok.map((data, index) => {
      return (
        <option key={data.id} value={data.id}>{data.munkakor}</option>
      )
    })

    useEffect(() => {
      munkakorokLekerese()
    }, []);

    const [validated, setValidated] = useState(false);
    const handleSubmit = (event) => {
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }
      setValidated(true);
      if (form.checkValidity() === true) {
        adatokKuldese();
        navigate('../oktatok')
      }
    };

    const adatokKuldese = () => {
      var config = {
        method: 'post',
        url: baseUrl + 'oktato',
        headers: { 
          'Accept': 'application/vnd.api+json', 
          'Content-Type': 'application/vnd.api+json', 
          'Authorization': 'Bearer '+token
        },
        data: {
          'vezeteknev': formVezeteknev,
          'keresztnev': formKeresztnev,
          'om_azonosito': formOMazonosito,
            
          'iskola_id': formIskolaID,
          'munkakor_id': formMunkakorID,
          
          'vegzettsegek': formVegzettsegek,
          'tovabbkepzesek': formTovabbkepzesek,
          'oneletrajz': formOneletrajz,
        }
      };
      axios(config)
      .then(function (response) {
        return
      })
      .catch(function (error) {
        //console.log(error);
        alert("Hiba történt.\n\n" + error.message)
      });
    };

  return (
    <>
    <h1>Új oktató rögzítése</h1>
    <Row pt={5}>
    <Col lg="6">
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Vezetéknév: </Form.Label>
            <Form.Control type="text" placeholder="Írd be a vezetéknevet!" value={formVezeteknev} required
              onChange={(e) => { setFormVezeteknev(e.target.value) }}/>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Keresztnév: </Form.Label>
            <Form.Control type="text" placeholder="Írd be a kesztnevet!" value={formKeresztnev} required
              onChange={(e) => { setFormKeresztnev(e.target.value) }}/>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>OM azonosító: </Form.Label>
            <Form.Control type="text" placeholder="OM azonosító" value={formOMazonosito} required
              onChange={(e) => { setFormOMazonosito(e.target.value) }}/>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Iskola: {formIskolaNev}</Form.Label>
          </Form.Group>
         
          <Form.Group className="mb-3">
            <Form.Label>Munkakör</Form.Label>
            <Form.Select defaultValue={formMunkakorID} onChange={(e) => { setFormMunkakorID(e.target.value) }}>
              <option value='0' disabled>Válassz ki egy munkakört!</option>
              {arrMunkakorok}
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Végzettségek: </Form.Label>
            <Form.Control as="textarea" rows={3} placeholder="Végzettségek" value={formVegzettsegek} 
              onChange={(e) => { setFormVegzettsegek(e.target.value) }}
              />
          </Form.Group>
          
          <Form.Group className="mb-3">
            <Form.Label>Továbbképzések: </Form.Label>
            <Form.Control as="textarea" rows={3} placeholder="Továbbképzések" value={formTovabbkepzesek} 
              onChange={(e) => { setFormTovabbkepzesek(e.target.value) }}/>
          </Form.Group>
          

          <Form.Group className="mb-3">
            <Form.Label>Önéletrajz: </Form.Label>
            <Form.Control type="text" placeholder="Önéletrajz" value={formOneletrajz} 
              onChange={(e) => { setFormOneletrajz(e.target.value) }}/>
          </Form.Group>

         {/*  <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>Önéletrajz feltöltése</Form.Label>
            <Form.Control type="file" />
          </Form.Group> */}

        <Button type="submit" className='mb-3'>Adatok küldése</Button>

        </Form>
        <Button variant='light' className="mb-3" onClick = {()=>navigate('../oktatok')}>Vissza az oktatók listájához</Button>
      </Col>
    </Row>
    </>
  )
}

export default New