import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import axios from 'axios'
import { Button, Card, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'
import MagyarDatum from '../../components/MagyarDatum'
import useUrl from '../../components/useUrl';


function Show() {
    let { index } = useParams();
	const navigate = useNavigate();
    const [data, setData] = useState({});
	const baseUrl = useUrl()
	
	const token = localStorage.getItem("token")

	const adatokLekerese = async () => {
		try {
			const res = await axios.get(baseUrl + 'ertekeles/'+index, {
				headers: { 
					'Content-type': 'application/vnd.api+json',
					'Accept': 'application/vnd.api+json',
					'Authorization': `Bearer ${token}` 
				}
			});
			setData(res.data.data);
		} catch (error) {
        console.log(error);
        alert("Hiba történt.\n\n" + error.message)}
	};

	const [szempontok, setSzempontok] = useState([]);
    const szempontokLekerese =  async () => {
      try {
        const res =  await axios.get(baseUrl + 'szempont/szempontsor/'+data.szempontsor_id, {
          headers: { 
            'Content-type': 'application/vnd.api+json',
            'Accept': 'application/vnd.api+json',
            'Authorization': `Bearer ${token}`
          }
        });
        //console.log(res.data.data)
        setSzempontok(res.data.data);
      } catch (err) {
        alert(err + " - Szempontsor ID: " + data.szempontsor_id);
      }
    };
    
    const arrSzempontok = szempontok.map((data) => {
      return (
        <span>{data.terulet}</span>
      )
    })

	useEffect(() => {
		adatokLekerese()
	}, []);

	useEffect(() => {
		szempontokLekerese()
	}, [data.szempontsor_id]);

	const vissza = () => { navigate('../ertekelesek')}
    
  return (
	
    <div>
		
        <h1>Az értékelés részletei</h1><br/>
		<p>Időpont: {data.idopont}</p>
		<p>Szempontsor: {data.szempontsor_megnevezes}</p>
		<Card>
			<Card.Header>
				<h2>{data.oktato_vezeteknev} {data.oktato_keresztnev}</h2>
				<p><em>{data.oktato_iskola_nev}</em></p>
			</Card.Header>
			<Card.Body>
				<Row>
					<Col sm={9}>
					<p>{arrSzempontok[0]}: <b>{data.terulet1}</b></p>
					<p>{arrSzempontok[1]}: <b>{data.terulet2}</b></p>
					<p>{arrSzempontok[2]}: <b>{data.terulet3}</b></p>
					<p>{arrSzempontok[3]}: <b>{data.terulet4}</b></p>
					<p>{arrSzempontok[4]}: <b>{data.terulet5}</b></p>
					<p>{arrSzempontok[5]}: <b>{data.terulet6}</b></p>
					<p>{arrSzempontok[6]}: <b>{data.terulet7}</b></p>
					<p>{arrSzempontok[7]}: <b>{data.terulet8}</b></p>
					<p>{arrSzempontok[8]}: <b>{data.terulet9}</b></p>
					<p>{arrSzempontok[9]}: <b>{data.terulet10}</b></p>
					</Col>
					<Col sm={3}>
					<h5>Súlyozott eredmény:</h5>
					<h2 className='pb-5'> {data.eredmeny}</h2>
					</Col>
				</Row>

				
			</Card.Body>
			<Card.Footer>
				{data.lezarva ? (<p>Lezárva.</p>) : (<p>Nincs lezárva.</p>)}
			</Card.Footer>
		</Card>
		
		<hr/>
		<p>Létrehozva: <MagyarDatum datum = {data.created_at}/></p>
		<p>Módosítva: <MagyarDatum datum = {data.updated_at}/></p>
		
		<Button variant='secondary' onClick={vissza}>Vissza az értékelések listájához</Button>
    </div>
  )
}
export default Show