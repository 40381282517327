import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import axios from 'axios'
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'
import MagyarDatum from '../../components/MagyarDatum';
import useUrl from '../../components/useUrl';

function Show() {
    let { index } = useParams();
	const navigate = useNavigate();
    const [data, setData] = useState({});
	const baseUrl = useUrl()
	
	const token = localStorage.getItem("token")

	const adatokLekerese = async () => {
		try {
			const res = await axios.get(baseUrl + 'users/'+index, {
				headers: { 
					'Content-type': 'application/vnd.api+json',
					'Accept': 'application/vnd.api+json',
					'Authorization': `Bearer ${token}` 
				}
			});
			setData(res.data.data);
			
		} catch (error) {
			//console.log(error);
			alert("Hiba történt.\n\n" + error.message)
		}
	};
	useEffect(() => {adatokLekerese()}, []);

	const vissza = () => { navigate(-1)}
    
  return (
	
    <div>
        <h1>{data.name}</h1>
		<br></br>
		{/* <p>Id: {data.id}</p> */}
        <p><b>Név:</b> {data.vezeteknev} {data.keresztnev}</p>
		<p><b>Email:</b> {data.email}</p>
		<p><b>Iskola:</b> {data.iskola_nev}</p>
		<p><b>Munkakör:</b> {data.munkakor_munkakor}</p>
		<p><b>Írási jogosultság:</b> {data.jog_iras ? "igen" : "nem"}</p>
		<p><b>Értéklési jogosultság:</b> {data.jog_ertekeles ? "igen" : "nem"}</p>
		<p><b>Adminisztrációs jogosultság:</b> {data.jog_admin ? "igen" : "nem"}</p>
		<hr></hr>
		<p>Létrehozva: <MagyarDatum datum = {data.created_at}></MagyarDatum></p>
		<p>Módosítva: <MagyarDatum datum = {data.updated_at}></MagyarDatum></p>
		<br></br>
		
		
		<Button variant='info' onClick={vissza}>Vissza</Button>
    </div>
  )
}
export default Show