import {useState, useEffect} from 'react';
import {Form, Row, Col, Button} from 'react-bootstrap';
import axios from 'axios';
import {useParams, useLocation, useNavigate} from 'react-router-dom'
import useUrl from '../../components/useUrl';

function Edit() {

  let { index } = useParams();
  const {state} = useLocation();
  const baseUrl = useUrl()
  const { name, email, 
    vezeteknev, keresztnev, iskolaID, munkakorID, jogIras, jogErtekeles, jogAdmin} = state || {};
    
    const [formName, setFormName] = useState(state.name);
    const [formEmail, setFormEmail] = useState(state.email);
    const [formVezeteknev, setFormVezeteknev] = useState(state.vezeteknev);
    const [formKeresztnev, setFormKeresztnev] = useState(state.keresztnev);
    const [formIskolaID, setFormIskolaID] = useState(state.iskolaID);
    const [formMunkakorID, setFormMunkakorID] = useState(state.munkakorID);
    const [formJogIras, setFormJogIras] = useState(state.jogIras);
    const [formJogErtekeles, setFormJogErtekeles] = useState(state.jogErtekeles);
    const [formJogAdmin, setFormJogAdmin] = useState(state.jogAdmin);

    const [iskolak, setIskolak] = useState([]);
    const [munkakorok, setMunkakorok] = useState([]);

    const token = localStorage.getItem("token")
    const navigate = useNavigate()

    const iskolakLekerese = async () => {
      try {
        const res = await axios.get(baseUrl + 'iskola', {
          headers: { 
            'Content-type': 'application/vnd.api+json',
            'Accept': 'application/vnd.api+json',
            'Authorization': `Bearer ${token}`
          }
        });
        setIskolak(res.data.data);
      } catch (error) {
        alert("Hiba történt.\n\n" + error.message)
      }
    };
    
    const arrIskolak = iskolak.map((data, index) => {
      return (
        <option key={data.id} value={data.id} selected={data.id == formIskolaID}>
          {data.nev}</option>
      )
    })

    const munkakorokLekerese = async () => {
      try {
        const res = await axios.get(baseUrl + 'munkakor', {
          headers: { 
            'Content-type': 'application/vnd.api+json',
            'Accept': 'application/vnd.api+json',
            'Authorization': `Bearer ${token}`
          }
        });
        setMunkakorok(res.data.data);
      } catch (err) {
        alert(err.message);
      }
    };

    const arrMunkakorok = munkakorok.map((data, index) => {
      return (
        <option key={data.id} value={data.id} selected={data.id == formMunkakorID}>
          {data.munkakor}</option>
      )
    })

    useEffect(() => {
      iskolakLekerese()
      munkakorokLekerese()
    }, []);

    const handleOnChangeIrasiJog = () => {
      setFormJogIras(!formJogIras)
    };

    const handleOnChangeErtekelesiJog = () => {
      setFormJogErtekeles(!formJogErtekeles)
    };

    const handleOnChangeAdminJog = () => {
      setFormJogAdmin(!formJogAdmin)
    };

    const [validated, setValidated] = useState(false);
    const handleSubmit = (event) => {
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }
      setValidated(true);
      if (form.checkValidity() === true) {
        adatokKuldese();
        navigate('../users')
      }
    };

    const adatokKuldese = () => {
      var config = {
        method: 'patch',
        url: baseUrl + 'users/'+index,
        headers: { 
          'Accept': 'application/vnd.api+json', 
          'Content-Type': 'application/vnd.api+json', 
          'Authorization': 'Bearer '+token
        },
        data: {
          'name': formName,
          'email': formEmail,
          'vezeteknev': formVezeteknev,
          'keresztnev': formKeresztnev,
          'iskola_id': formIskolaID,
          'munkakor_id': formMunkakorID,
          'jog_iras': formJogIras,
          'jog_ertekeles': formJogErtekeles,
          'jog_admin': formJogAdmin          
        }
      };
      axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
        return
      })
      .catch(function (error) {
        console.log(error);
      });
    };

  return (
    <>
    <h1>Felhasználó adatainak szerkesztése</h1>
    <Row pt={5}>
      <Col lg="6">
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Felhasználónév: </Form.Label>
            <Form.Control type="text" placeholder="Felhasználónév" value={formName} required
              onChange={(e) => { setFormName(e.target.value) }}/>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>E-mail: </Form.Label>
            <Form.Control type="email" placeholder="E-mail cím" value={formEmail} required
              onChange={(e) => { setFormEmail(e.target.value) }}/>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Vezetéknév: </Form.Label>
            <Form.Control type="text" placeholder="Vezetéknév" value={formVezeteknev} required
              onChange={(e) => { setFormVezeteknev(e.target.value) }}/>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Keresztnév: </Form.Label>
            <Form.Control type="text" placeholder="Keresztnév" value={formKeresztnev} required
              onChange={(e) => { setFormKeresztnev(e.target.value) }}/>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Select defaultValue={formIskolaID} onChange={(e) => { setFormIskolaID(e.target.value) }}>
              <option value='0' disabled>Válassz ki egy iskolát!</option>
              {arrIskolak}
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Select defaultValue={formMunkakorID} onChange={(e) => { setFormMunkakorID(e.target.value) }}>
              <option value='0' disabled>Válassz ki egy munkakört!</option>
              {arrMunkakorok}
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Check id="irasiJog" type="switch" label="Írási jogosultság"
              checked={formJogIras}
              onChange={handleOnChangeIrasiJog}
            />
            <Form.Check id="ertekelesiJog" type="switch" label="Értékelési jogosultság"
              checked={formJogErtekeles}
              onChange={handleOnChangeErtekelesiJog}
            />
            <Form.Check id="adminJog" type="switch" label="Adminisztrációs jogosultság"
              checked={formJogAdmin}
              onChange={handleOnChangeAdminJog}
            />
          </Form.Group>          

          <Button type="submit" className='mb-3'>Adatok küldése</Button>
        </Form>
      </Col>
    </Row>
    </>
  )
}

export default Edit