import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import axios from 'axios'
import { Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'
import useUrl from '../../components/useUrl';

function Show() {
    let { index } = useParams();
	const navigate = useNavigate();
	const baseUrl = useUrl()
    const [data, setData] = useState({});

	const token = localStorage.getItem("token")

	const adatokLekerese = async () => {
		try {
			const res = await axios.get(baseUrl + 'oktato/'+index, {
				headers: { 
					'Content-type': 'application/vnd.api+json',
					'Accept': 'application/vnd.api+json',
					'Authorization': `Bearer ${token}` 
				}
			});
			setData(res.data.data);
		} catch (error) {
        //console.log(error);
        alert("Hiba történt.\n\n" + error.message)
		}
	};
	useEffect(() => {adatokLekerese()}, []);

	const navOktatok = () => { navigate('../oktatok')}
    
  return (
    <div>
        <h1 className='mb-5'>Az oktató adatai</h1>
		{/* <p>Id: {data.id}</p> */}
        <h3> {data.vezeteknev} {data.keresztnev}</h3>
		<p>OM azonosító: {data.om_azonosito}<br></br></p>
		<hr></hr>
		<p><b>Iskola neve:</b> {data.iskola_nev}</p>
		<p><b>Munkakör:</b> {data.munkakor_munkakor}</p>
		<Form.Group className="mb-3">
			<Form.Label><b>Végzettségek</b></Form.Label>
			<Form.Control as="textarea" rows={4} readOnly value={data.vegzettsegek}/>
      	</Form.Group>
		<Form.Group className="mb-3">
			<Form.Label><b>Továbbképzések</b></Form.Label>
			<Form.Control as="textarea" rows={4} readOnly value={data.tovabbkepzesek}/>
      	</Form.Group>
		<p><b>Önéletrajz:</b> {data.oneletrajz}</p>
		<Button className='mt-3' variant='secondary' onClick={navOktatok}>Vissza</Button>
    </div>
  )
}
export default Show