import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import axios from 'axios'
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'
import MagyarDatum from '../../components/MagyarDatum'
import useUrl from '../../components/useUrl';

function Show() {
    let { index } = useParams();
	const navigate = useNavigate();
	const baseUrl = useUrl()
    const [data, setData] = useState({});

	const token = localStorage.getItem("token")

	const adatokLekerese = async () => {
		try {
			const res = await axios.get(useUrl + 'iskola/'+index, {
				headers: { 
					'Content-type': 'application/vnd.api+json',
					'Accept': 'application/vnd.api+json',
					'Authorization': `Bearer ${token}` 
				}
			});
			setData(res.data.data);
		} catch (error) {
        //console.log(error);
        alert("Hiba történt.\n\n" + error.message)		}
	};
	useEffect(() => {adatokLekerese()}, []);

	const navBlogs = () => { navigate('../iskolak')}
    
  return (
    <div>
        <h1>Az iskola adatai</h1><br />
		<p><b>Id: </b>{data.id}</p>
        <p><b>Iskola neve:</b> {data.nev}</p>
		<p><b>Cím:</b> {data.iranyitoszam} {data.varos} {data.utca}</p>
		<p><b>Telefon:</b> {data.telefon}</p>
		<p><b>E-mail:</b> {data.email}</p>
		<p><b>Bejegyzés dátuma:</b> <MagyarDatum datum ={data.created_at} /></p>
		<p><b>Módosítva:</b> <MagyarDatum datum ={data.updated_at} /></p>
		<br></br>
		<Button variant='secondary' onClick={navBlogs}>Vissza</Button>
    </div>
  )
}
export default Show