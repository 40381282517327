import axios from "axios";
import {useParams, useNavigate} from 'react-router-dom'
import {Button} from 'react-bootstrap'
import useUrl from '../../components/useUrl'

function Delete() {
  let { index } = useParams();
  const token = localStorage.getItem("token")
  const navigate = useNavigate()
  const baseUrl = useUrl()
  
  const elemTorlese = () => {
    var config = {
      method: 'delete',
      url: baseUrl + 'users/'+index,
      headers: { 
        'Accept': 'application/vnd.api+json', 
        'Content-Type': 'application/vnd.api+json', 
        'Authorization': 'Bearer '+token
      }
    };
    axios(config)
    .then(function (response) {
      //console.log(JSON.stringify(response.data))
      navigate('../users')
      return
    })
    .catch(function (error) {
         //console.log(error);
         alert("Hiba történt.\n\n" + error.message)
    })
  }

  const vissza = () => {
    navigate("../users")
  }

  return (
    <div className="text-center">
        <h1>Biztos?</h1>
        <Button variant='info' onClick={elemTorlese}>Igen</Button>
        <Button className="ms-3" variant='danger' onClick={vissza}>Nem</Button>
    </div>
  )
}

export default Delete