import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import axios from 'axios'
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'
import Szempontok from '../Szempontok'
import MagyarDatum from '../../components/MagyarDatum';
import useUrl from '../../components/useUrl';

function Show() {
    let { index } = useParams();
	const navigate = useNavigate();
    const [data, setData] = useState({});
	const baseUrl = useUrl()
	
	const token = localStorage.getItem("token")

	const adatokLekerese = async () => {
		try {
			const res = await axios.get(baseUrl + 'szempontsor/'+index, {
				headers: { 
					'Content-type': 'application/vnd.api+json',
					'Accept': 'application/vnd.api+json',
					'Authorization': `Bearer ${token}` 
				}
			});
			setData(res.data.data);
		} catch (error) {
	        console.log(error);
			alert("Hiba történt.\n\n" + error.message)
			return
		}
	};
	useEffect(() => {adatokLekerese()}, []);

	const vissza = () => { navigate('../szempontsorok')}
    
  return (
	data.id ?
    <div>
        <h1>A szempontsor részletei</h1><br/>
		{/* <p>Id: {data.id}</p> */}
        <h2>{data.megnevezes}</h2>
		<p><b>{data.iskola_nev}</b></p>
		<p>Lezárva: {data.lezarva ? "igen" : "nem"}</p>
		<p>Létrehozva: <MagyarDatum datum = {data.created_at}/></p>
		<p>Módosítva: <MagyarDatum datum = {data.updated_at}/></p>
		<br />
		<br />
		<Szempontok szempontsor = {data}/>
		<br />
		<Button variant='secondary' onClick={vissza}>Vissza a szempontsorok listájához</Button>
    </div>
	:
	<></>
  )
}
export default Show