const NoPage = () => {
    return (
      <>
        <h1>404</h1>
        <p>Az oldal nem található.</p>
      </>
      
      );
  };
  
  export default NoPage;