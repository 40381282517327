import {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom'
import {Form, Row, Col, Button} from 'react-bootstrap';
import axios from 'axios';
import useUrl from '../../components/useUrl';

function New() {

  const baseUrl = useUrl()

    const [formOktatoID, setFormOktatoID] = useState(0);
    const [formSzempontsorID, setFormSzempontsorID] = useState(0);
    const [formIdopont, setFormIdopont] = useState('');
    const [formTerulet1, setFormTerulet1] = useState('');
    const [formTerulet2, setFormTerulet2] = useState('');
    const [formTerulet3, setFormTerulet3] = useState('');
    const [formTerulet4, setFormTerulet4] = useState('');
    const [formTerulet5, setFormTerulet5] = useState('');
    const [formTerulet6, setFormTerulet6] = useState('');
    const [formTerulet7, setFormTerulet7] = useState('');
    const [formTerulet8, setFormTerulet8] = useState('');
    const [formTerulet9, setFormTerulet9] = useState('');
    const [formTerulet10, setFormTerulet10] = useState('');
    const [formEredmeny, setFormEredmeny] = useState('');
    const [formLezarva, setFormLezarva] = useState(false);
    
    const token = localStorage.getItem("token")
    const userMunkakor = localStorage.getItem("munkakorID")
    const navigate = useNavigate()

    const [oktatok, setOktatok] = useState([]);
    const oktatokLekerese = async () => {
      try {
        const res = await axios.get(useUrl + 'oktato', {
          headers: { 
            'Content-type': 'application/vnd.api+json',
            'Accept': 'application/vnd.api+json',
            'Authorization': `Bearer ${token}`
          }
        });
        //console.log(res.data.data)
        setOktatok(res.data.data);
        return
      } catch (err) {
        console.log(err)
        alert("Hiba történt.\n\n" + err.message);
      }
    };
    const arrOktatok = oktatok.map((data) => {
      return (
        <option key={data.id} value={data.id} selected={data.id === formOktatoID}>
          {data.vezeteknev} {data.keresztnev} {data.om_azonosito}</option>
      )
    })

    const [szempontsor, setSzempontsor] = useState([]);
    const szempontsorLekerese = async () => {
      try {
        const res = await axios.get(useUrl + 'szempontsor', {
          headers: { 
            'Content-type': 'application/vnd.api+json',
            'Accept': 'application/vnd.api+json',
            'Authorization': `Bearer ${token}`
          }
        });
        setSzempontsor(res.data.data);
      } catch (err) {
        alert(err);
      }
    };
    const arrSzempontsor = szempontsor.map((data) => {
      return (
        <option key={data.id} value={data.id}>
          {data.megnevezes}</option>
      )
    })

    const [szempontok, setSzempontok] = useState([]);
    const szempontokLekerese =  async () => {
      try {
        const res =  await axios.get(baseUrl + 'szempont/szempontsor/'+formSzempontsorID, {
          headers: { 
            'Content-type': 'application/vnd.api+json',
            'Accept': 'application/vnd.api+json',
            'Authorization': `Bearer ${token}`
          }
        });
        //console.log(res.data.data)
        setSzempontok(res.data.data);
      } catch (err) {
        alert(err + " - Szempontsor ID: " + formSzempontsorID);
      }
    };
    
    const max = []
    const sulyszorzo = []
    const arrSzempontok = szempontok.map((data) => {
      sulyszorzo.push(data.sulyszorzo)
      max.push(data.ertek)
      return (
        <span>{data.terulet}</span>
      )
    })

    const sulyozott = (pontszam, sulyszorzo) => {return (pontszam*sulyszorzo)}

    const eredmenyKiszamitasa = () => {
      var eredmeny = sulyozott(formTerulet1,sulyszorzo[0]) +
        sulyozott(formTerulet2,sulyszorzo[1]) +
        sulyozott(formTerulet3,sulyszorzo[2]) +
        sulyozott(formTerulet4,sulyszorzo[3]) +
        sulyozott(formTerulet5,sulyszorzo[4]) +
        sulyozott(formTerulet6,sulyszorzo[5]) +
        sulyozott(formTerulet7,sulyszorzo[6]) +
        sulyozott(formTerulet8,sulyszorzo[7]) +
        sulyozott(formTerulet9,sulyszorzo[8]) +
        sulyozott(formTerulet10,sulyszorzo[9]);
      eredmeny ? setFormEredmeny(eredmeny) : setFormEredmeny(0)
    }
   
    useEffect(() => {
      oktatokLekerese()
      szempontsorLekerese()
    }, []);

    useEffect(() => {
      szempontokLekerese()
    }, [formSzempontsorID]);

    useEffect(() => {
      eredmenyKiszamitasa()
    });
    

    const handleOnChangeLezarva = () => {
      setFormLezarva(!formLezarva)
    };

    const pontszamKezelese = (pontszam, max) => {
      if (pontszam < 0) {pontszam = 0}
      if (pontszam > max) {pontszam = max}
      return pontszam
    }

    const [validated, setValidated] = useState(false);
    const handleSubmit = (event) => {
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }
      setValidated(true);
      if (form.checkValidity() === true) {
        adatokKuldese();
        navigate('../ertekelesek')
      }
    };

    const adatokKuldese = () => {
      var config = {
        method: 'post',
        url: baseUrl + 'ertekeles',
        headers: { 
          'Accept': 'application/vnd.api+json', 
          'Content-Type': 'application/vnd.api+json', 
          'Authorization': 'Bearer '+token,
        },
        data: {
          'oktato_id': formOktatoID,
          'szempontsor_id': formSzempontsorID,
          'idopont': formIdopont,
          'terulet1': formTerulet1,
          'terulet2': formTerulet2,
          'terulet3': formTerulet3,
          'terulet4': formTerulet4,
          'terulet5': formTerulet5,
          'terulet6': formTerulet6,
          'terulet7': formTerulet7,
          'terulet8': formTerulet8,
          'terulet9': formTerulet9,
          'terulet10': formTerulet10,
          'lezarva': formLezarva,
          'eredmeny': formEredmeny
        }
      };
      axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
        return
      })
      .catch(function (error) {
        //console.log(error);
        alert(
          error.message+'\n\n'+error.response.data.message
          )
        });
    };

  return (
    <>

    <h1>Új értékelés rögzítése</h1>
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Row pt={5}>
        <Col lg="9">
          <Form.Group className="mb-3">
            <Form.Select defaultValue={formOktatoID} onChange={(e) => { setFormOktatoID(e.target.value) }} >
              <option value='0' disabled>Válassz ki az oktatót!</option>
              {arrOktatok}
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Select defaultValue={formSzempontsorID} onChange={(e) => { setFormSzempontsorID(e.target.value) }} >
              <option value='0' disabled>Válassz ki a szempontsort!</option>
              {arrSzempontsor}
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Időpont: </Form.Label>
            <Form.Control type="text" placeholder="ÉÉÉÉ-HH-NN" value={formIdopont} required 
              onChange={(e) => { setFormIdopont(e.target.value) }}/>
          </Form.Group>

          {formSzempontsorID ?  
          <>
            <fieldset className="bg-light p-3">
              <Form.Group className="mb-3">
                <Row>
                  <Col sm={6}><Form.Label>1. {arrSzempontok[0]}</Form.Label></Col>
                  <Col sm={2}><Form.Control type="number" placeholder="pontszám" value={formTerulet1} required
                  onChange={(e) => { setFormTerulet1( pontszamKezelese(e.target.value, max[0]) ) }}/> </Col>
                  <Col sm={2}><Form.Label>Súlyozott (x {sulyszorzo[0]}):</Form.Label></Col>
                  <Col sm={2}><Form.Control type="number" disabled value={sulyozott(formTerulet1,sulyszorzo[0])}/></Col>
                </Row>
              </Form.Group>

              <Form.Group className="mb-3">
                <Row>
                  <Col sm={6}><Form.Label>2. {arrSzempontok[1]}</Form.Label></Col>
                  <Col sm={2}><Form.Control type="number" placeholder="pontszám" value={formTerulet2} required
                  onChange={(e) => { setFormTerulet2(pontszamKezelese(e.target.value, max[1]) ) }}/> </Col>
                  <Col sm={2}><Form.Label>Súlyozott (x {sulyszorzo[1]}):</Form.Label></Col>
                  <Col sm={2}><Form.Control type="number" disabled value={sulyozott(formTerulet2,sulyszorzo[1])}/></Col>
                </Row>
              </Form.Group>

              <Form.Group className="mb-3">
                <Row>
                  <Col sm={6}><Form.Label>3. {arrSzempontok[2]}</Form.Label></Col>
                  <Col sm={2}><Form.Control type="number" placeholder="pontszám" value={formTerulet3} required
                  onChange={(e) => { setFormTerulet3(pontszamKezelese(e.target.value, max[2]) ) }}/> </Col>
                  <Col sm={2}><Form.Label>Súlyozott (x {sulyszorzo[2]}):</Form.Label></Col>
                  <Col sm={2}><Form.Control type="number" disabled value={sulyozott(formTerulet3,sulyszorzo[2])}/></Col>
                </Row>
              </Form.Group>

              <Form.Group className="mb-3">
                <Row>
                  <Col sm={6}><Form.Label>4. {arrSzempontok[3]}</Form.Label></Col>
                  <Col sm={2}><Form.Control type="number" placeholder="pontszám" value={formTerulet4} required
                  onChange={(e) => { setFormTerulet4(pontszamKezelese(e.target.value, max[3]) ) }}/> </Col>
                  <Col sm={2}><Form.Label>Súlyozott (x {sulyszorzo[3]}):</Form.Label></Col>
                  <Col sm={2}><Form.Control type="number" disabled value={sulyozott(formTerulet4,sulyszorzo[3])}/></Col>
                </Row>
              </Form.Group>

              <Form.Group className="mb-3">
                <Row>
                  <Col sm={6}><Form.Label>5. {arrSzempontok[4]}</Form.Label></Col>
                  <Col sm={2}><Form.Control type="number" placeholder="pontszám" value={formTerulet5} required
                  onChange={(e) => { setFormTerulet5(pontszamKezelese(e.target.value, max[4]) ) }}/> </Col>
                  <Col sm={2}><Form.Label>Súlyozott (x {sulyszorzo[4]}):</Form.Label></Col>
                  <Col sm={2}><Form.Control type="number" disabled value={sulyozott(formTerulet5,sulyszorzo[4])}/></Col>
                </Row>
              </Form.Group>

              <Form.Group className="mb-3">
                <Row>
                  <Col sm={6}><Form.Label>6. {arrSzempontok[5]}</Form.Label></Col>
                  <Col sm={2}><Form.Control type="number" placeholder="pontszám" value={formTerulet6} required
                  onChange={(e) => { setFormTerulet6(pontszamKezelese(e.target.value, max[5]) ) }}/> </Col>
                  <Col sm={2}><Form.Label>Súlyozott (x {sulyszorzo[5]}):</Form.Label></Col>
                  <Col sm={2}><Form.Control type="number" disabled value={sulyozott(formTerulet6,sulyszorzo[5])}/></Col>
                </Row>
              </Form.Group>

              <Form.Group className="mb-3">
                <Row>
                  <Col sm={6}><Form.Label>7. {arrSzempontok[6]}</Form.Label></Col>
                  <Col sm={2}><Form.Control type="number" placeholder="pontszám" value={formTerulet7} required
                  onChange={(e) => { setFormTerulet7(pontszamKezelese(e.target.value, max[6]) ) }}/> </Col>
                  <Col sm={2}><Form.Label>Súlyozott (x {sulyszorzo[6]}):</Form.Label></Col>
                  <Col sm={2}><Form.Control type="number" disabled value={sulyozott(formTerulet7,sulyszorzo[6])}/></Col>
                </Row>
              </Form.Group>

              <Form.Group className="mb-3">
                <Row>
                  <Col sm={6}><Form.Label>8. {arrSzempontok[7]}</Form.Label></Col>
                  <Col sm={2}><Form.Control type="number" placeholder="pontszám" value={formTerulet8} required
                  onChange={(e) => { setFormTerulet8(pontszamKezelese(e.target.value, max[7]) ) }}/> </Col>
                  <Col sm={2}><Form.Label>Súlyozott (x {sulyszorzo[7]}):</Form.Label></Col>
                  <Col sm={2}><Form.Control type="number" disabled value={sulyozott(formTerulet8,sulyszorzo[7])}/></Col>
                </Row>
              </Form.Group>

              <Form.Group className="mb-3">
                <Row>
                  <Col sm={6}><Form.Label>9. {arrSzempontok[8]}</Form.Label></Col>
                  <Col sm={2}><Form.Control type="number" placeholder="pontszám" value={formTerulet9} required
                  onChange={(e) => { setFormTerulet9(pontszamKezelese(e.target.value, max[8]) ) }}/> </Col>
                  <Col sm={2}><Form.Label>Súlyozott (x {sulyszorzo[8]}):</Form.Label></Col>
                  <Col sm={2}><Form.Control type="number" disabled value={sulyozott(formTerulet9,sulyszorzo[8])}/></Col>
                </Row>
              </Form.Group>

              <Form.Group className="mb-3">
                <Row>
                  <Col sm={6}><Form.Label>10. {arrSzempontok[9]}</Form.Label></Col>
                  <Col sm={2}><Form.Control type="number" placeholder="pontszám" value={formTerulet10} required
                  onChange={(e) => { setFormTerulet10(pontszamKezelese(e.target.value, max[9]) ) }}/> </Col>
                  <Col sm={2}><Form.Label>Súlyozott (x {sulyszorzo[9]}):</Form.Label></Col>
                  <Col sm={2}><Form.Control type="number" disabled value={sulyozott(formTerulet10,sulyszorzo[9])}/></Col>
                </Row>
              </Form.Group>
            </fieldset>
          </>
          : <></>}
       
      </Col>
      <Col >
        {(userMunkakor =='3' || userMunkakor=='4') ?
          (<Form.Group className="mb-3">
            <Form.Check id="irasiJog" type="switch" label="Lezárva"
              checked={formLezarva}
              onChange={handleOnChangeLezarva} /> 
            <p className='small text-secondary'>{formLezarva ? ('') : ('(Nincs lezárva)')}</p>
          </Form.Group>) :
          (<></>)}
        
          <h2 className='pb-5'> Eredmény: {formEredmeny}</h2>
          
          <Button type ="submit">Adatok küldése</Button>
      </Col>
    </Row> 
    </Form>
    </>
  )
}

export default New