import { Container, Col } from "react-bootstrap";
import { Outlet, Navigate } from "react-router-dom";
import Navbar from "../components/Navbar"
import ArrowUp from "../components/ArrowUp"

const Layout = () => {
  
  const iskolaNev = localStorage.getItem("iskolaNev")

  return (
 
    <div className="hatter">
      <header className="text-center">
        <img src="logo_otr.png" /> 
        <h1 className="text-center">Oktatói Teljesítményértékelési Rendszer</h1>
        <p className="text-small">{iskolaNev}</p>
      </header>
      <nav className="mb-3">
        <Navbar />
      </nav>
      <Container className="p-3 bg-white lekerekitett"><Outlet /></Container>
      <footer className="p-5 text-center"><p className="small">Készítette: Rettegi Attila, Büki Zoltán, Gyurcsó László - Szombathely</p>
        <ArrowUp variant='secondary'/>
      </footer>
    </div>
    
  )
};

export default Layout;