
import {FaArrowUp} from 'react-icons/fa'
import './ArrowUp.css'
import Button from 'react-bootstrap/Button';
import { useEffect, useState } from 'react'


const ArrowUp = (props) => {
  const variant = props.variant
  const [showButton, setShowButton] = useState(false);
  
  const handleClick = () => {
    window.scrollTo(0, 0)
  }

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 100) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);

  return (
    showButton ?
      <div>
          <Button className='buttonUp' variant={variant} onClick={handleClick}> <FaArrowUp/></Button>
      </div>
      : <></>
    )
}

export default ArrowUp