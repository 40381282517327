import {useState} from 'react';
import {Form, Row, Col, Button} from 'react-bootstrap';
import axios from 'axios';
import {useParams, useLocation, useNavigate} from 'react-router-dom'
import useUrl from '../../components/useUrl';

function Edit() {

  let { index } = useParams();
  const {state} = useLocation();
  const baseUrl = useUrl()
  const { munkakor } = state || {};
    const [formMunkakor, setFormMunkakor] = useState(state.munkakor);

    const token = localStorage.getItem("token")
    const navigate = useNavigate()

    const [validated, setValidated] = useState(false);
    const handleSubmit = (event) => {
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }
      setValidated(true);
      if (form.checkValidity() === true) {
        adatokKuldese();
        navigate('../munkakorok')
      }
    };

    const adatokKuldese = () => {
      var config = {
        method: 'patch',
        url: baseUrl + 'munkakor/'+index,
        headers: { 
          'Accept': 'application/vnd.api+json', 
          'Content-Type': 'application/vnd.api+json', 
          'Authorization': 'Bearer '+token
        },
        data: {
          'munkakor': formMunkakor
        }
      };
      axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
        return
      })
      .catch(function (error) {
        //console.log(error);
        alert("Hiba történt.\n\n" + error.message)      });
      
    };

  return (
    <>
    <h1>Szerkesztés</h1>
    <Row pt={5}>
      <Col lg="6">
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formName">
            <Form.Label>Munkakör: </Form.Label>
            <Form.Control type="text" placeholder="Munkakör" value={formMunkakor} required
              onChange={(e) => { setFormMunkakor(e.target.value) }}/>
          </Form.Group>
          <Button type="submit" className = 'mb-3'>Adatok küldése</Button>
        </Form>
        <Button variant='secondary' onClick = {()=>navigate(-1)}>Vissza</Button>
      </Col>
    </Row>
    </>
  )
}

export default Edit